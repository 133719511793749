.refund-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.refund-text {
  display: inline-block;
}
.refund-text01 {
  display: inline-block;
}
.refund-text02 {
  display: inline-block;
}
.refund-text03 {
  display: inline-block;
}
.refund-content5 {
  display: inline-block;
}
.refund-text06 {
  display: inline-block;
}
.refund-text07 {
  display: inline-block;
}
.refund-text08 {
  display: inline-block;
}
.refund-text09 {
  display: inline-block;
}
.refund-text10 {
  display: inline-block;
}
