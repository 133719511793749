.about-comp-stats-about-comp-stats {
  position: relative;
}
.about-comp-stats-max-width {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-container {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-comp-stats-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.about-comp-stats-container2 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-container3 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-container4 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
}
.about-comp-stats-container5 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-container6 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
}
.about-comp-stats-container7 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-container8 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.about-comp-stats-text14 {
  display: inline-block;
}
.about-comp-stats-text15 {
  display: inline-block;
}
.about-comp-stats-text16 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text17 {
  display: inline-block;
}
.about-comp-stats-text18 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text19 {
  display: inline-block;
}
.about-comp-stats-text20 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text21 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text22 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text23 {
  display: inline-block;
}
.about-comp-stats-text24 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text25 {
  display: inline-block;
}
.about-comp-stats-text26 {
  display: inline-block;
}
.about-comp-stats-text27 {
  display: inline-block;
  text-align: center;
}
.about-comp-stats-text28 {
  display: inline-block;
}
.about-comp-stats-text29 {
  display: inline-block;
}
@media(max-width: 767px) {
  .about-comp-stats-title {
    text-align: center;
  }
  .about-comp-stats-container2 {
    align-self: flex-start;
  }
  .about-comp-stats-text {
    align-self: center;
  }
  .about-comp-stats-container3 {
    align-self: flex-start;
  }
  .about-comp-stats-text02 {
    align-self: center;
  }
  .about-comp-stats-container4 {
    align-self: flex-start;
  }
  .about-comp-stats-text04 {
    align-self: center;
  }
  .about-comp-stats-container5 {
    align-self: flex-start;
  }
  .about-comp-stats-text06 {
    align-self: center;
  }
  .about-comp-stats-text07 {
    text-align: center;
  }
  .about-comp-stats-container6 {
    align-self: flex-start;
  }
  .about-comp-stats-text08 {
    align-self: center;
  }
  .about-comp-stats-container7 {
    align-self: flex-start;
  }
  .about-comp-stats-text10 {
    align-self: center;
  }
  .about-comp-stats-container8 {
    align-self: flex-start;
  }
  .about-comp-stats-text12 {
    align-self: center;
  }
  .about-comp-stats-text14 {
    align-self: center;
  }
  .about-comp-stats-text15 {
    text-align: center;
  }
  .about-comp-stats-text19 {
    align-self: center;
  }
  .about-comp-stats-text20 {
    text-align: center;
  }
  .about-comp-stats-text23 {
    align-self: center;
  }
  .about-comp-stats-text25 {
    align-self: center;
  }
  .about-comp-stats-text26 {
    align-self: center;
  }
  .about-comp-stats-text28 {
    align-self: center;
  }
  .about-comp-stats-text29 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .about-comp-stats-title {
    align-self: center;
    text-align: center;
  }
  .about-comp-stats-text15 {
    align-self: center;
    text-align: center;
  }
}
