.pricing-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.pricing-text {
  color: var(--dl-color-theme-neutral-dark);
  width: 100%;
  display: inline-block;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
.pricing-text1 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 64px;
  text-align: center;
}
.pricing-text4 {
  color: var(--dl-color-theme-neutral-dark);
  width: 100%;
  display: inline-block;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
.pricing-text5 {
  display: inline-block;
  font-size: 40px;
}
.pricing-text6 {
  display: inline-block;
  font-size: 20px;
}
.pricing-text7 {
  display: inline-block;
}
@media(max-width: 791px) {
  .pricing-text1 {
    font-size: 24px;
  }
}
@media(max-width: 479px) {
  .pricing-text1 {
    font-size: 16px;
  }
}
