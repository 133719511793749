.pricing-development-pricing23 {
  gap: 0;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
}
.pricing-development-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-development-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-shrink: 0;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.pricing-development-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-text {
  text-align: center;
}
.pricing-development-text01 {
  text-align: center;
}
.pricing-development-content1 {
  gap: 0;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
}
.pricing-development-container {
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-development-column1 {
  gap: var(--dl-space-space-twounits);
  width: 20%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 1px;
}
.pricing-development-price {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-price1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-text02 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-development-text03 {
  width: auto;
  font-size: 64px;
  text-align: center;
}
.pricing-development-text04 {
  font-size: 24px;
  margin-top: -36px;
  text-align: center;
}
.pricing-development-column2 {
  gap: var(--dl-space-space-twounits);
  width: 20%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
  border-top-width: 1px;
}
.pricing-development-price2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-price3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-text05 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-development-text06 {
  font-size: 64px;
  text-align: center;
}
.pricing-development-text07 {
  font-size: 24px;
  margin-top: -36px;
  text-align: center;
}
.pricing-development-column3 {
  gap: var(--dl-space-space-twounits);
  width: 20%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  padding-bottom: 32px;
  background-color: var(--dl-color-theme-neutral-light);
  border-top-width: 1px;
}
.pricing-development-price4 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-price5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-development-text08 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-development-text10 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  font-size: 24px;
  margin-top: -36px;
  text-align: center;
}
.pricing-development-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-development-content2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-development-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container03 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container04 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text13 {
  width: auto;
}
.pricing-development-container05 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container06 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text15 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  height: auto;
  align-self: center;
}
.pricing-development-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container08 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container09 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container10 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container11 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text19 {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
}
.pricing-development-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container13 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container14 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container15 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container16 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text23 {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
}
.pricing-development-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container18 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container19 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container20 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container21 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon04 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container23 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container24 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container25 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container26 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text28 {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
}
.pricing-development-container27 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-development-content21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-development-container28 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container29 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container30 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container31 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container32 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon10 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container33 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container34 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container35 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container36 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container37 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon16 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container38 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container39 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container40 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container41 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container42 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon22 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container43 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container44 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container45 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container46 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text34 {
  width: auto;
}
.pricing-development-container47 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-text35 {
  fill: var(--dl-color-theme-primary1);
  color: var(--dl-color-theme-primary1);
  width: auto;
}
.pricing-development-container48 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container49 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container50 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container51 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container52 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon30 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container53 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container54 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.pricing-development-container55 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container56 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container57 {
  flex: 1;
  width: 20%;
  justify-content: center;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
}
.pricing-development-icon36 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container58 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
  align-self: stretch;
}

.pricing-development-container59 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: stretch;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  align-self: stretch;
}
.pricing-development-container60 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container61 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-container62 {
  flex: 1;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
  justify-content: center;
}
.pricing-development-icon42 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container63 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: stretch;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: row;
}
.pricing-development-container64 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.pricing-development-container65 {
  flex: 1;
  width: 20%;
  justify-content: center;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 0px;
}
.pricing-development-container66 {
  flex: 1;
  justify-content: center;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-accent1);
  border-bottom-width: 0px;
}
.pricing-development-container67 {
  flex: 1;
  justify-content: center;
  width: 20%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-theme-neutral-light);
  border-bottom-width: 0px;
}
.pricing-development-icon48 {
  fill: var(--dl-color-theme-primary1);
}
.pricing-development-container68 {
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.pricing-development-container69 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pricing-development-button {
  width: auto;
  align-self: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-neutral-dark);
}
.pricing-development-text41 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-size: 20px;
}
.pricing-development-text42 {
  color: var(--dl-color-theme-neutral-dark);
  width: 100%;
  display: inline-block;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
.pricing-development-text43 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 64px;
  text-align: center;
}


@media(max-width: 991px) {
  .pricing-development-content1 {
    flex-direction: row;
  }
  .pricing-development-container68 {
    width: 100%;
  }
}
@media(max-width: 791px) {
  .pricing-development-content1 {
    flex-direction: column;
  }
  .pricing-development-column1 {
    width: 100%;
    border-top-width: 1px;
  }
  .pricing-development-text03 {
    font-size: 24px;
  }
  .pricing-development-text04 {
    font-size: 24px;
  }
  .pricing-development-column2 {
    width: 100%;
  }
  .pricing-development-text06 {
    font-size: 24px;
  }
  .pricing-development-text07 {
    font-size: 24px;
  }
  .pricing-development-column3 {
    width: 100%;
  }
  .pricing-development-text10 {
    font-size: 24px;
  }
  .pricing-development-text43 {
    font-size: 24px;
  }
}
@media(max-width: 479px) {
  .pricing-development-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing-development-column1 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing-development-text03 {
    font-size: 16px;
  }
  .pricing-development-text04 {
    font-size: 16px;
  }
  .pricing-development-column2 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing-development-text06 {
    font-size: 16px;
  }
  .pricing-development-text07 {
    font-size: 16px;
  }
  .pricing-development-column3 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .pricing-development-text10 {
    font-size: 16px;
  }
  .pricing-development-button {
    width: 100%;
  }
  .pricing-development-text43 {
    font-size: 16px;
  }
}
