.about-intro-about-intro {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.about-intro-text1 {
  display: inline-block;
}

@media(max-width: 991px) {
  .about-intro-about-intro{
    padding: 50px;
  }
}
@media(max-width: 550px) {
  .about-intro-about-intro{
    padding: 30px;
  }
}
@media(max-width: 375px) {
  .about-intro-about-intro{
    padding: 15px;
  }
}