.web-d-content-web-d-content {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.web-d-content-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.web-d-content-content {
  align-self: stretch;
  align-items: flex-start;
}
.web-d-content-ul {
  align-items: flex-start;
}
.web-d-content-text {
  display: inline-block;
}
.web-d-content-text01 {
  display: inline-block;
}
.web-d-content-content11 {
  display: inline-block;
}
.web-d-content-content31 {
  display: inline-block;
}
.web-d-content-text06 {
  display: inline-block;
  font-size: 20px;
}
.web-d-content-text07 {
  display: inline-block;
}
.web-d-content-content42 {
  display: inline-block;
}
.web-d-content-text10 {
  display: inline-block;
}
.web-d-content-text11 {
  display: inline-block;
}
.web-d-content-text12 {
  display: inline-block;
}
.web-d-content-text13 {
  display: inline-block;
}
.web-d-content-text14 {
  display: inline-block;
}
.web-d-content-text15 {
  display: inline-block;
}
.web-d-content-text16 {
  display: inline-block;
}
.web-d-content-text17 {
  display: inline-block;
  align-self: flex-start;
}
@media(max-width: 1600px) {
  .web-d-content-heading21 {
    font-size: 18px;
  }
  .web-d-content-heading22 {
    font-size: 18px;
  }
  .web-d-content-heading23 {
    font-size: 18px;
  }
  .web-d-content-heading24 {
    font-size: 18px;
  }
  .web-d-content-text03 {
    line-height: 2;
  }
  .web-d-content-text11 {
    font-size: 18px;
  }
  .web-d-content-text12 {
    font-size: 18px;
  }
  .web-d-content-text14 {
    font-size: 18px;
  }
  .web-d-content-text15 {
    font-size: 18px;
  }
}
