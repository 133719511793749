.home-steps-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-steps-max-width {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-steps-container1 {
  align-items: start;
}
.home-steps-section-header {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}
.home-steps-text {
  font-size: 56px;
}
.home-steps-text01 {
  font-size: 24px;
}
.home-steps-container2 {
  grid-area: span 1/span 1/span 1/span 1;
}
.home-steps-container3 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.home-steps-text02 {
  font-size: 40px;
}
.home-steps-text03 {
  font-size: 20px;
  text-align: center;
}
.home-steps-text04 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-steps-container4 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent2);
}
.home-steps-text05 {
  font-size: 40px;
}
.home-steps-text06 {
  font-size: 20px;
  text-align: center;
}
.home-steps-text07 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-steps-container5 {
  top: 10%;
  position: sticky;
  transform: rotate(-2deg);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-accent1);
}
.home-steps-text08 {
  font-size: 40px;
}
.home-steps-text09 {
  font-size: 20px;
  text-align: center;
}
.home-steps-text10 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.home-steps-container6 {
  top: 10%;
  position: sticky;
  transform: rotate(2deg);
  background-color: var(--dl-color-theme-accent2);
}
.home-steps-text11 {
  font-size: 40px;
}
.home-steps-text12 {
  font-size: 20px;
  text-align: center;
}
.home-steps-text13 {
  top: var(--dl-space-space-unit);
  right: var(--dl-space-space-unit);
  position: absolute;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

@media(max-width: 991px) {
  .home-steps-max-width {
    flex-direction: column;
  }
  .thq-grid-2{
    display: block;
  }
  .home-steps-section-header {
    position: static;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-steps-text{
    width: 100%;
  }
  .home-steps-container3 {
    width: 100%;
  }
  .home-steps-container4 {
    width: 100%;
  }
  .home-steps-container5 {
    width: 100%;
  }
  .home-steps-container6 {
    width: 100%;
  }
}

@media(max-width: 650px) {
  .home-steps-text02, .home-steps-text04, .home-steps-text05, .home-steps-text07, .home-steps-text08,
  .home-steps-text10, .home-steps-text11, .home-steps-text13{
    font-size: 36px;
  }
  .home-steps-text02, .home-steps-text05, .home-steps-text08,
  .home-steps-text11 {
    width: 70%;
  }
  .home-steps-text{
    font-size: 48px;
  }
}
