.pricing-digital-mkt-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.pricing-digital-mkt-text {
  font-size: 48px;
  margin-top: var(--dl-space-space-threeunits);
}
.pricing-digital-mkt-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing-digital-mkt-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-digital-mkt-column {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  flex-direction: column;
}
.pricing-digital-mkt-icon {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing-digital-mkt-price {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-price1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-text01 {
  font-weight: 600;
}
.pricing-digital-mkt-text02 {
  font-size: 48px;
}
.pricing-digital-mkt-list {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-digital-mkt-list-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-text03 {
  font-weight: 700;
}
.pricing-digital-mkt-list-item01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-button {
  width: 100%;
  display: none;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent1);
}
.pricing-digital-mkt-text08 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.pricing-digital-mkt-column1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  background-color: var(--dl-color-theme-accent1);
}
.pricing-digital-mkt-icon14 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing-digital-mkt-price2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-price3 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-text09 {
  font-weight: 600;
}
.pricing-digital-mkt-text10 {
  font-size: 48px;
}
.pricing-digital-mkt-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-digital-mkt-list-item05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-text11 {
  font-weight: 700;
}
.pricing-digital-mkt-list-item06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-button1 {
  width: 100%;
  display: none;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent2);
}
.pricing-digital-mkt-text16 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.pricing-digital-mkt-column2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-twounits);
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-theme-accent2);
  align-self: stretch;
}
.pricing-digital-mkt-icon24 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  align-self: flex-end;
}
.pricing-digital-mkt-price4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-price5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-digital-mkt-text17 {
  font-weight: 600;
}
.pricing-digital-mkt-text18 {
  font-size: 48px;
}
.pricing-digital-mkt-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-digital-mkt-list-item10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-text19 {
  font-weight: 700;
}
.pricing-digital-mkt-list-item11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-list-item14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing-digital-mkt-button2 {
  width: 100%;
  display: none;
  text-decoration: none;
}
.pricing-digital-mkt-text24 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
}
.pricing-digital-mkt-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.pricing-digital-mkt-container2 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.pricing-digital-mkt-button3 {
  width: auto;
  align-self: center;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent2);
}
.pricing-digital-mkt-text26 {
  fill: var(--dl-color-theme-neutral-light);
  color: var(--dl-color-theme-neutral-light);
  font-size: 20px;
}
.pricing-digital-mkt-text27 {
  color: var(--dl-color-theme-neutral-dark);
  width: 100%;
  display: inline-block;
  font-size: 32px;
  font-style: normal;
  text-align: center;
  font-family: "Bricolage Grotesque";
  font-weight: 600;
}
.pricing-digital-mkt-root-class-name {
  top: 574px;
  left: 0px;
  position: static;
}
@media(max-width: 1600px) {
  .pricing-digital-mkt-button {
    background-color: var(--dl-color-theme-accent1);
  }
  .pricing-digital-mkt-text08 {
    fill: var(--dl-color-theme-neutral-light);
    color: var(--dl-color-theme-neutral-light);
  }
  .pricing-digital-mkt-button1 {
    background-color: var(--dl-color-theme-accent2);
  }
  .pricing-digital-mkt-button2 {
    background-color: var(--dl-color-theme-primary1);
  }
  .pricing-digital-mkt-button3 {
    background-color: var(--dl-color-theme-accent2);
  }
}
@media(max-width: 991px) {
  .pricing-digital-mkt-container {
    flex-direction: column;
  }
  .pricing-digital-mkt-column {
    width: 100%;
    margin-right: 0%;
  }
  .pricing-digital-mkt-column1 {
    width: 100%;
    margin-right: 0%;
    margin-left: 0%;
  }
  .pricing-digital-mkt-column2 {
    width: 100%;
    margin-left: 0%;
  }
  .pricing-digital-mkt-container2 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .pricing-digital-mkt-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing-digital-mkt-button {
    width: 100%;
  }
  .pricing-digital-mkt-button1 {
    width: 100%;
  }
  .pricing-digital-mkt-button2 {
    width: 100%;
  }
  .pricing-digital-mkt-button3 {
    width: 100%;
  }
}
