.pricing-faq-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing-faq-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-faq-section-title {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing-faq-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.pricing-faq-text {
  text-align: center;
}
.pricing-faq-text1 {
  text-align: center;
}
.pricing-faq-list {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing-faq-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.pricing-faq-trigger {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-faq1-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-faq-icons-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-icon {
  width: 24px;
  height: 24px;
}
.pricing-faq-icon02 {
  width: 24px;
  height: 24px;
}
.pricing-faq-container03 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-faq-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.pricing-faq-trigger1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-faq2-question {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-faq-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-icon04 {
  width: 24px;
  height: 24px;
}
.pricing-faq-icon06 {
  width: 24px;
  height: 24px;
}
.pricing-faq-container06 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-faq-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.pricing-faq-trigger2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-faq2-question1 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-faq-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-icon08 {
  width: 24px;
  height: 24px;
}
.pricing-faq-icon10 {
  width: 24px;
  height: 24px;
}
.pricing-faq-container09 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-faq-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.pricing-faq-trigger3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-faq2-question2 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-faq-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-icon12 {
  width: 24px;
  height: 24px;
}
.pricing-faq-icon14 {
  width: 24px;
  height: 24px;
}
.pricing-faq-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-faq-faq5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  flex-direction: column;
}
.pricing-faq-trigger4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-faq1-question1 {
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.pricing-faq-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.pricing-faq-icon16 {
  width: 24px;
  height: 24px;
}
.pricing-faq-icon18 {
  width: 24px;
  height: 24px;
}
.pricing-faq-container15 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing-faq-content1 {
  align-self: center;
}
.pricing-faq-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing-faq-text7 {
  text-align: center;
}
.pricing-faq-text8 {
  text-align: center;
}
.pricing-faq-button {
  text-decoration: none;
}
@media(max-width: 991px) {
  .pricing-faq-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .pricing-faq-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
