.cloud-header-cloud-header {
  gap: var(--dl-space-space-twounits);
  height: 450px;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  justify-content: center;
}
.cloud-header-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 450px;
  position: absolute;
  object-fit: fill;
}
.cloud-header-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 300px;
  display: none;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255,255,255,0.7);
}
.cloud-header-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  margin: 0px;
  z-index: 1;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.cloud-header-content {
  gap: 0;
  display: flex;
  align-self: center;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  padding-left: 10%;
  padding-top: 5%;
}
.cloud-header-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.cloud-header-text2 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  font-size: 28px;
  align-self: flex-start;
  text-align: center;
}
.cloud-header-text3 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  font-size: 108px;
  align-self: flex-start;
  text-align: left;
}
@media(max-width: 1600px) {
  .cloud-header-text {
    color: var(--dl-color-theme-secondary2);
    font-size: 108px;
  }
  .cloud-header-text3 {
    color: var(--dl-color-theme-secondary2);
    font-size: 108px;
  }
}

@media(max-width: 991px) {
  .cloud-header-text {
    text-align: left;
  }
  .cloud-header-text1 {
    text-align: left;
  }
  .cloud-header-text2 {
    text-align: center;
  }
  .cloud-header-text3 {
    text-align: center;
  }
  .cloud-header-image{
    height: 300px;
  }
  .cloud-header-cloud-header{
    height: 300px;
  }
}
@media(max-width: 479px) {
  .cloud-header-actions {
    width: 100%;
    flex-direction: column;
  }
}
