.services-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.services-text {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}
