.about-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 56px;
  text-align: center;
}
.about-text01 {
  display: inline-block;
  font-size: 20px;
  text-align: center;
}
.about-text02 {
  display: inline-block;
}
.about-text07 {
  display: inline-block;
}
.about-text08 {
  display: inline-block;
}
.about-text09 {
  display: inline-block;
}
.about-text10 {
  display: inline-block;
}
.about-text11 {
  display: inline-block;
}
.about-text12 {
  display: inline-block;
}
.about-text13 {
  display: inline-block;
}
.about-text14 {
  display: inline-block;
}
.about-text15 {
  display: inline-block;
}
.about-text16 {
  display: inline-block;
  text-align: center;
}
.about-text17 {
  display: inline-block;
  text-align: center;
}
.about-text18 {
  display: inline-block;
  text-align: center;
}
.about-text19 {
  display: inline-block;
  text-align: center;
}
.about-text20 {
  display: inline-block;
  text-align: center;
}
.about-text21 {
  display: inline-block;
  text-align: center;
}
.about-text22 {
  display: inline-block;
  text-align: center;
}
.about-text23 {
  display: inline-block;
}
.about-text24 {
  display: inline-block;
}
.about-text25 {
  display: inline-block;
}
.about-text26 {
  display: inline-block;
}
.about-text27 {
  display: inline-block;
}
.about-text28 {
  display: inline-block;
}
.about-text29 {
  display: inline-block;
}
.about-text30 {
  display: inline-block;
}
.about-text31 {
  display: inline-block;
}
@media(max-width: 991px) {
  .about-text {
    text-align: center;
  }
  .about-text01 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .about-text19 {
    text-align: center;
  }
  .about-text23 {
    text-align: center;
  }
  .about-text25 {
    align-self: center;
  }
  .about-text26 {
    align-self: center;
  }
  .about-text27 {
    align-self: center;
  }
  .about-text28 {
    align-self: center;
  }
  .about-text29 {
    align-self: center;
  }
  .about-text30 {
    align-self: center;
  }
  .about-text31 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .about-text23 {
    align-self: center;
    text-align: center;
  }
}
