.contact-form-contact9 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form-image1 {
  flex: 1;
  width: auto;
  height: auto;
  max-width: 640px;
  border-radius: var(--dl-radius-radius-radius4);
}
.contact-form-content {
  gap: 0;
  flex: 1;
  align-items: stretch;
}
.contact-form-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-input {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form-input1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  flex-direction: column;
}
.contact-form-container {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-form-checkbox {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-form-text6 {
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: left;
  font-family: Roboto;
  font-weight: 400;
  line-height: 150%;
  font-stretch: normal;
  text-decoration: none;
}
.contact-form-button {
  width: auto;
  align-self: center;
}

@media(max-width: 1200px) {
  .contact-form-max-width {
    flex-direction: column;
  }
  .contact-form-content {
    width: 100%;
  }
}
@media(max-width: 791px) {
  .contact-form-image1 {
    width: 100%;
  }
}
