.digital-marketing-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.digital-marketing-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: #c5b6e0;
  display: inline-block;
  font-size: 144px;
  text-align: center;
}
.digital-marketing-text01 {
  display: inline-block;
  font-size: 20px;
  text-align: center;
}
.digital-marketing-content1,.digital-marketing-content2, .digital-marketing-content3, .digital-marketing-content4,
.digital-marketing-text50, .digital-marketing-text51, .digital-marketing-text52, .digital-marketing-text53,
.digital-marketing-text54, .digital-marketing-text55, .digital-marketing-text62, .digital-marketing-text63,
.digital-marketing-text64, .digital-marketing-text65, .digital-marketing-text66, .digital-marketing-text67 {
  font-size: 24px;
}
.digital-marketing-text07 {
  font-weight: 700;
}
.digital-marketing-text12 {
  font-weight: 700;
}
.digital-marketing-text35 {
  font-weight: 700;
}
.digital-marketing-text36 {
  font-weight: 700;
}
.digital-marketing-text40 {
  font-weight: 700;
}
.digital-marketing-text41 {
  font-weight: 700;
}
.digital-marketing-text45 {
  font-weight: 700;
}
.digital-marketing-text46 {
  font-weight: 700;
}

.digital-marketing-text56 {
  display: inline-block;
  align-self: flex-start;
}
.digital-marketing-text57 {
  display: inline-block;
}
.digital-marketing-text58 {
  display: inline-block;
}
.digital-marketing-text59 {
  display: inline-block;
}
.digital-marketing-text60 {
  display: inline-block;
}
.digital-marketing-text61 {
  display: inline-block;
}

.digital-marketing-text68 {
  display: inline-block;
  align-self: flex-start;
}
.digital-marketing-text69 {
  display: inline-block;
}
.digital-marketing-text70 {
  display: inline-block;
}
.digital-marketing-text71 {
  display: inline-block;
}
.digital-marketing-text72 {
  display: inline-block;
}
.digital-marketing-text73 {
  display: inline-block;
}

@media(max-width: 1400px) {
  .digital-marketing-text {
    font-size: 120px;
  }
}

@media(max-width: 991px) {
  .digital-marketing-text {
    text-align: center;
    font-size: 84px;
  }
  .digital-marketing-text01 {
    text-align: center;
  }
}

@media(max-width: 551px) {
  .digital-marketing-text {
    font-size: 64px;
  }
}