.cloud-services-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.cloud-services-text {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  font-size: 108px;
  align-self: flex-start;
  text-align: left;
}
.cloud-services-text001 {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  display: inline-block;
  font-size: 28px;
  align-self: flex-start;
  text-align: left;
}
.cloud-services-content1 {
  display: inline-block;
}
.cloud-services-text002, .cloud-services-text009,  .cloud-services-text018, .cloud-services-text025,
.cloud-services-text029, .cloud-services-text031, .cloud-services-text035, .cloud-services-text048, .cloud-services-text052,
.cloud-services-text056, .cloud-services-text060, .cloud-services-text069, .cloud-services-text073,
.cloud-services-text077, .cloud-services-text081, .cloud-services-text090, .cloud-services-text094,
.cloud-services-text098, .cloud-services-text102, .cloud-services-text105, .cloud-services-text106,
.cloud-services-text107, .cloud-services-text111, .cloud-services-text112, .cloud-services-text113,
.cloud-services-text114, .cloud-services-text115 {
  font-size: 24px;
}
.cloud-services-text005, .cloud-services-text015, .cloud-services-text039, .cloud-services-text108 {
  font-size: 36px;
  font-weight: 700;
}

.cloud-services-text021, .cloud-services-text026, .cloud-services-text030, .cloud-services-text034,
.cloud-services-text047, .cloud-services-text051,.cloud-services-text055, .cloud-services-text059,
.cloud-services-text068, .cloud-services-text072, .cloud-services-text076, .cloud-services-text080,
.cloud-services-text089, .cloud-services-text093, .cloud-services-text097, .cloud-services-text101 {
  font-weight: 700;
  font-size: 24px;
}

.cloud-services-text042, .cloud-services-text064, .cloud-services-text085, .cloud-services-text116,
.cloud-services-text117, .cloud-services-text118, .cloud-services-text119, .cloud-services-text120,
.cloud-services-text121, .cloud-services-text122, .cloud-services-text123 {
  font-weight: 700;
  font-size: 28px;
}

.cloud-services-text109, .cloud-services-text110, .cloud-services-text124, .cloud-services-text125,
.cloud-services-text126, .cloud-services-text127, .cloud-services-text128, .cloud-services-text129 {
  font-weight: 700;
  font-size: 30px;
}
.cloud-services-text042-1, .cloud-services-text064-1, .cloud-services-text085-1 {
  font-weight: 700;
  font-size: 22px;
}


@media(max-width: 1600px) {
  .cloud-services-text {
    color: var(--dl-color-theme-secondary2);
    font-size: 108px;
  }
}


@media(max-width: 991px) {
  .cloud-services-text {
    text-align: left;
    font-size: 56px;
  }
  .cloud-services-text001 {
    text-align: left;
    font-size: 24px;
  }
}


@media(max-width: 500px) {
  .cloud-services-text001 {
    font-size: 24px;
    width: 100%;
  }
}