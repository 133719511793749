.home-header-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.home-header-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-header-content {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-header-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
}
.home-header-button {
  font-size: 22px;
  text-decoration: none;
}
.home-header-text3 {
  font-size: 22px;
}
.home-header-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-header-row-container {
  width: 100%;
}
.home-header-placeholder-image {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image01 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image02 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image03 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image04 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image05 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image06 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image07 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image08 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image09 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.home-header-row-container1 {
  width: 100%;
}
.home-header-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.home-header-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.home-header-container1 {
  display: contents;
}
.home-header-text4 {
  display: inline-block;
}
.home-header-text5 {
  display: inline-block;
}
.home-header-text6 {
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 72px;
}

@media(max-width: 991px) {
  .home-header-row-container {
    height: 300px;
  }
  .home-header-strip {
    height: 300px;
  }
  .home-header-row-container1 {
    height: 300px;
  }
  .home-header-text6 {
    text-align: center;
  }
}
@media(max-width: 791px) {
  .home-header-column {
    align-self: center;
    align-items: flex-start;
  }
  .home-header-content1 {
    width: 100%;
  }
  .home-header-text6 {
    width: auto;
    height: auto;
    font-size: 56px;
    text-align: left;
  }
}
@media(max-width: 479px) {
  .home-header-actions {
    width: 100%;
    flex-direction: column;
  }
  .home-header-button {
    width: 100%;
  }
  .home-header-row-container {
    height: 300px;
  }
  .home-header-strip {
    height: 300px;
    min-width: 100%;
  }
  .home-header-row-container1 {
    height: 300px;
  }
  .home-header-strip2 {
    height: 300px;
  }
}
