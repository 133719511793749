.about-dm-stats-about-dm-stats {
  width: 100%;
  position: relative;
  flex-direction: column;
}
.about-dm-stats-max-width {
  gap: var(--dl-space-space-fourunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.about-dm-stats-container {
  max-width: 50%;
}
.about-dm-stats-container1 {
  align-self: center;
  align-items: flex-start;
  margin-left: auto;
}
.about-dm-stats-container2 {
  width: 100%;
}
.about-dm-stats-container3 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.about-dm-stats-container4 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.about-dm-stats-container5 {
  width: 100%;
}
.about-dm-stats-container6 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.about-dm-stats-container7 {
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.about-dm-stats-text09 {
  display: inline-block;
}
.about-dm-stats-text10 {
  display: inline-block;
}
.about-dm-stats-text11 {
  display: inline-block;
}
.about-dm-stats-text12 {
  display: inline-block;
}
.about-dm-stats-text13 {
  display: inline-block;
}
.about-dm-stats-text14 {
  display: inline-block;
}
.about-dm-stats-text15 {
  display: inline-block;
}
.about-dm-stats-text16 {
  display: inline-block;
}
.about-dm-stats-text17 {
  display: inline-block;
}
@media(max-width: 991px) {
  .about-dm-stats-max-width {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
  }
  .about-dm-stats-container {
    width: 100%;
    max-width: 100%;
  }
  .about-dm-stats-container1 {
    align-self: flex-start;
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .about-dm-stats-image {
    width: 100%;
  }
}
