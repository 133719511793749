.services-header-header30 {
  gap: var(--dl-space-space-twounits);
}
.services-header-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.services-header-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255,255,255,0.7);
}
.services-header-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  flex-direction: column;
}
.services-header-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.services-header-text {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 48px;
  text-align: center;
}
.services-header-text1 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 24px;
  text-align: center;
}
.services-header-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.services-header-button {
  font-size: 22px;
  text-decoration: none;
  background-color: var(--dl-color-theme-accent1);
}
.services-header-text2 {
  text-align: center;
}

@media(max-width: 1600px) {
  .services-header-text {
    color: var(--dl-color-theme-neutral-dark);
  }
  .services-header-text1 {
    color: var(--dl-color-theme-neutral-dark);
    font-size: 24px;
  }
}
@media(max-width: 991px) {
  .services-header-text {
    text-align: center;
  }
  .services-header-text1 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .services-header-actions {
    width: 100%;
    flex-direction: column;
  }
  .services-header-button {
    width: 100%;
  }
}
