.web-d-header-web-d-header {
  gap: var(--dl-space-space-twounits);
  height: 350px;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  justify-content: center;
}
.web-d-header-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 400px;
  position: absolute;
  object-fit: cover;
}
.web-d-header-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 300px;
  display: none;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255,255,255,0.7);
}
.web-d-header-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: none;
  z-index: 1;
  align-self: center;
  margin-top: var(--dl-space-space-sixunits);
  align-items: center;
  flex-direction: column;
}
.web-d-header-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.web-d-header-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.web-d-header-text2 {
  display: inline-block;
  font-size: 20px;
  text-align: center;
}
.web-d-header-text3 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 56px;
  text-align: center;
}
@media(max-width: 991px) {
  .web-d-header-text {
    text-align: center;
  }
  .web-d-header-text1 {
    text-align: center;
  }
  .web-d-header-text2 {
    text-align: center;
  }
  .web-d-header-text3 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .web-d-header-actions {
    width: 100%;
    flex-direction: column;
  }
}
