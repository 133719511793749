.header-container {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.header-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.header-container2 {
  width: 421px;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: center;
}
.header-text3 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.header-text4 {
  display: inline-block;
  text-align: center;
  font-weight: 400;
}
.header-text5 {
  display: inline-block;
  margin-top: var(--dl-space-space-sixunits);
}
