.pricing-contact-card-pricing-contact-card {
  gap: 0;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing-contact-card-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing-contact-card-accent2-bg {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: var(--dl-color-theme-accent2);
}
.pricing-contact-card-accent2-bg:hover {
  transform: scale3d(1.1,1.1,1.1);
}
.pricing-contact-card-accent1-bg {
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(-2deg) skew(0deg, 0deg);
  align-items: center;
  border-radius: var(--dl-radius-radius-cardradius);
  justify-content: space-between;
  transform-style: preserve-3d;
  background-color: var(--dl-color-theme-accent1);
}
.pricing-contact-card-container1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(1deg) skew(0deg, 0deg);
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-cardradius);
  padding-right: var(--dl-space-space-fourunits);
  padding-bottom: var(--dl-space-space-sixunits);
}
.pricing-contact-card-container1:hover {
  color: var(--dl-color-theme-neutral-light);
  background-color: var(--dl-color-theme-neutral-dark);
}
.pricing-contact-card-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.pricing-contact-card-actions {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.pricing-contact-card-navlink {
  font-size: 22px;
}
.pricing-contact-card-text3 {
  display: inline-block;
  font-size: 40px;
}
.pricing-contact-card-text4 {
  display: inline-block;
}
.pricing-contact-card-text5 {
  display: inline-block;
  font-size: 20px;
}
@media(max-width: 791px) {
  .pricing-contact-card-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .pricing-contact-card-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing-contact-card-actions {
    flex-wrap: wrap;
    align-self: stretch;
    justify-content: center;
  }
  .pricing-contact-card-navlink {
    flex: 1;
  }
}
