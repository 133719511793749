.legal-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.legal-text {
  display: inline-block;
}
.legal-text01 {
  display: inline-block;
}
.legal-text02 {
  display: inline-block;
}
.legal-text03 {
  display: inline-block;
}
.legal-content5 {
  display: inline-block;
}
.legal-text06 {
  display: inline-block;
}
.legal-text07 {
  display: inline-block;
}
.legal-text08 {
  display: inline-block;
}
.legal-text09 {
  display: inline-block;
}
.legal-text10 {
  display: inline-block;
}
