.feature-card-container {
  width: 45%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.feature-card-container1 {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.feature-card-icon {
  width: 40px;
  height: auto;
}
.feature-card-container2 {
  flex: initial;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text2 {
  display: inline-block;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-text3 {
  display: inline-block;
}

@media(max-width: 791px) {
  .feature-card-container {
    width: 100%;
  }
}