.services-features-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.services-features-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.services-features-container {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.services-features-text {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 56px;
}
.services-features-container1 {
  width: 100%;
}
.services-features-card {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.services-features-image {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text01 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text02 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text03 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  text-align: center;
}
.services-features-card1 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.services-features-image1 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text04 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text05 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button1 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text06 {
  text-align: center;
}
.services-features-card2 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.services-features-image2 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text07 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text08 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button2 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text09 {
  text-align: center;
}
.services-features-card3 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.services-features-image3 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text10 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text11 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button3 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text12 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  text-align: center;
}
.services-features-card4 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.services-features-image4 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text13 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text14 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button4 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text15 {
  text-align: center;
}
.services-features-card5 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.services-features-image5 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text16 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text17 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button5 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text18 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  text-align: center;
}
.services-features-card6 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary2);
}
.services-features-image6 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text20 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button6 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text21 {
  fill: var(--dl-color-theme-accent1);
  color: var(--dl-color-theme-accent1);
  text-align: center;
}
.services-features-card7 {
  color: var(--dl-color-theme-neutral-light);
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
}
.services-features-image7 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  object-fit: cover;
}
.services-features-text22 {
  color: var(--dl-color-theme-secondary1);
}
.services-features-text23 {
  color: var(--dl-color-theme-secondary1);
  text-align: center;
}
.services-features-button7 {
  fill: var(--dl-color-theme-neutral-dark);
  color: #943802;
  display: none;
  font-size: 22px;
  align-self: flex-end;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
}
.services-features-text24 {
  text-align: center;
}
.services-features-text25 {
  color: var(--dl-color-theme-secondary1);
  display: inline-block;
}

@media(max-width: 991px) {
  .services-features-max-width {
    flex-direction: column;
  }
  .services-features-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 791px) {
  .services-features-container {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .services-features-card {
    width: 100%;
  }
  .services-features-card1 {
    width: 100%;
  }
  .services-features-card2 {
    width: 100%;
  }
  .services-features-card3 {
    width: 100%;
  }
  .services-features-card4 {
    width: 100%;
  }
  .services-features-card5 {
    width: 100%;
  }
  .services-features-card6 {
    width: 100%;
  }
  .services-features-card7 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .services-features-button {
    width: 100%;
  }
  .services-features-button1 {
    width: 100%;
  }
  .services-features-button2 {
    width: 100%;
  }
  .services-features-button3 {
    width: 100%;
  }
  .services-features-button4 {
    width: 100%;
  }
  .services-features-button5 {
    width: 100%;
  }
  .services-features-button6 {
    width: 100%;
  }
  .services-features-button7 {
    width: 100%;
  }
}
