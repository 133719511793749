.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text {
  display: inline-block;
}
.home-text01 {
  width: auto;
  height: auto;
  display: inline-block;
  font-size: 72px;
}
.home-text02 {
  display: inline-block;
  text-align: center;
  font-size: 48px;
}
.home-text03 {
  display: inline-block;
}
.home-text04 {
  display: inline-block;
}
.home-text05 {
  display: inline-block;
}
.home-text06 {
  width: 100%;
  display: inline-block;
  padding: var(--dl-space-space-twounits);
  font-size: 64px;
  text-align: center;
}
.home-text07 {
  display: inline-block;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text08 {
  display: inline-block;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text09 {
  display: inline-block;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text10 {
  display: inline-block;
  font-size: 24px;
  text-align: center;
}
.home-container2 {
  display: contents;
}
@media(max-width: 991px) {
  .home-text01 {
    text-align: center;
  }
}
@media(max-width: 791px) {
  .home-text01 {
    width: auto;
    height: auto;
    font-size: 56px;
    text-align: left;
  }
  .home-text02 {
    font-size: 36px;
    text-align: left;
  }
  .home-text06{
    text-align: left;
    font-size: 48px;
  }
  .home-text07,.home-text08,.home-text09{
    font-size: 24px;
  }
  .home-text03,.home-text04,.home-text05{
    font-size: 18px;
  }

}
