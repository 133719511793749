.faq1-faq7 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.faq1-max-width {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.faq1-text1 {
  text-align: center;
}
.faq1-list-item1 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq1-faq1-question {
  font-style: normal;
  font-weight: 600;
}
.faq1-list-item2 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq1-faq2-question {
  font-style: normal;
  font-weight: 600;
}
.faq1-list-item3 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq1-faq3-question {
  font-style: normal;
  font-weight: 600;
}
.faq1-list-item4 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq1-faq4-question {
  font-style: normal;
  font-weight: 600;
}
.faq1-list-item5 {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.faq1-faq5-question {
  font-style: normal;
  font-weight: 600;
}
.faq1-content1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.faq1-text3 {
  text-align: center;
}
.faq1-container {
  display: flex;
  position: relative;
}
@media(max-width: 991px) {
  .faq1-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 791px) {
  .faq1-text1 {
    text-align: left;
  }
  .faq1-list {
    gap: var(--dl-space-space-twounits);
  }
  .faq1-button {
    width: 100%;
  }
}
