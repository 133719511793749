.digital-m-content-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.digital-m-content-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.digital-m-content-content {
  align-self: stretch;
  align-items: flex-start;
}
.digital-m-content-ul {
  align-items: flex-start;
}
.digital-m-content-heading1 {
  align-self: flex-start;
  font-size: 48px;
  font-weight: 700;
}
.digital-m-horiz-line{
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.digital-m-content-heading2 {
  font-size: 30px;
}
.digital-m-content-content41 {
  display: inline-block;
}
.digital-m-content-text05 {
  font-weight: 700;
}
.digital-m-content-text06 {
  font-weight: 700;
}
.digital-m-content-text10 {
  font-weight: 700;
}
.digital-m-content-text11 {
  font-weight: 700;
}
.digital-m-content-text15 {
  font-weight: 700;
}
.digital-m-content-text16 {
  font-weight: 700;
}
.digital-m-content-content21 {
  display: inline-block;
}
.digital-m-content-text25 {
  font-weight: 700;
}
.digital-m-content-text30 {
  font-weight: 700;
}
.digital-m-content-content11 {
  display: inline-block;
}
.digital-m-content-text43 {
  line-height: 2;
}
.digital-m-content-text44 {
  line-height: 2;
}
.digital-m-content-text45 {
  line-height: 2;
}
.digital-m-content-text46 {
  line-height: 2;
}
.digital-m-content-text47 {
  line-height: 2;
}
.digital-m-content-text48 {
  font-weight: 700;
  line-height: 2;
}
.digital-m-content-text49 {
  line-height: 2;
}
.digital-m-content-text50 {
  line-height: 2;
}
.digital-m-content-text51 {
  font-weight: 700;
  line-height: 2;
}
.digital-m-content-text52 {
  font-weight: 700;
  line-height: 2;
}
.digital-m-content-text53 {
  line-height: 2;
}
.digital-m-content-text54 {
  line-height: 2;
}
.digital-m-content-text55 {
  font-weight: 700;
  line-height: 2;
}
.digital-m-content-text56 {
  font-weight: 700;
  line-height: 2;
}
.digital-m-content-text57 {
  line-height: 2;
}
.digital-m-content-content31 {
  display: inline-block;
}
