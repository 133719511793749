.legal-content-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.legal-content-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.legal-content-content {
  align-self: stretch;
  align-items: flex-start;
}
.legal-content-li {
  align-items: flex-start;
}
.legal-content-heading1 {
  align-self: flex-start;
}
.legal-content-ul1 {
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
}
.legal-content-content1 {
  align-self: stretch;
  align-items: flex-start;
}
.legal-content-li07 {
  align-items: flex-start;
}
.legal-content-heading7 {
  align-self: flex-start;
}
.legal-content-ul3 {
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
}
.legal-content-heading8 {
  margin-bottom: var(--dl-space-space-unit);
}
.legal-content-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.legal-content-li09 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li10 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li11 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li12 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-heading9 {
  margin-bottom: var(--dl-space-space-unit);
}
.legal-content-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.legal-content-li14 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li15 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li16 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li17 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li18 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li19 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li20 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li21 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li22 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li23 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li24 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-heading91 {
  margin-bottom: var(--dl-space-space-unit);
}
.legal-content-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.legal-content-li26 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-li27 {
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.legal-content-text26 {
  display: inline-block;
}
.legal-content-content51 {
  display: inline-block;
}
.legal-content-text29 {
  display: inline-block;
}
.legal-content-text30 {
  display: inline-block;
}
.legal-content-text31 {
  display: inline-block;
}
.legal-content-text32 {
  display: inline-block;
}
.legal-content-text33 {
  display: inline-block;
}
.legal-content-text34 {
  display: inline-block;
}
.legal-content-text35 {
  display: inline-block;
}
.legal-content-text36 {
  display: inline-block;
}
@media(max-width: 791px) {
  .legal-content-ul1 {
    padding-left: var(--dl-space-space-threeunits);
  }
  .legal-content-ul3 {
    padding-left: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .legal-content-ul1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .legal-content-ul3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
}
