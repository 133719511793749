.digital-m-header-header30 {
  gap: var(--dl-space-space-twounits);
  height: 450px;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: center;
  justify-content: center;
}
.digital-m-header-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 450px;
  position: absolute;
  object-fit: fill;
}
.digital-m-header-container {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 300px;
  display: none;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255,255,255,0.7);
}
.digital-m-header-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  margin: 0px;
  z-index: 1;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.digital-m-header-content {
  gap: 0;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media(max-width: 1600px) {
  .digital-m-header-text {
    text-align: center;
  }
}

@media(max-width: 991px) {
  .digital-m-header-text {
    text-align: left;
  }
  .digital-m-header-text2 {
    text-align: center;
  }
}
