.navbar-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: auto;
  transition: 0.3s;
  align-items: center;
  padding-top: 10px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0;
  justify-content: space-between;
  background-color: var(--dl-color-theme-accent1);
}
.navbar-logo-div{
  width: 10%;
}
.navbar-items-center{
  width: 70%;
  justify-content: space-evenly;
  display: flex;
}

.navbar-image {
  width: 200px;
  display: none;
  object-fit: cover;
}
.navbar-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-navbar {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-services-nav {
  cursor: pointer;
  /* display: inline-block; */
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.navbar-navlink {
  font-size: 28px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  margin-right: 4px;
  vertical-align: middle;
  text-decoration: none;
}
.navbar-dropdown-arrow {
  transition: 0.3s;
}
.navbar-icon {
  width: 18px;
  height: 18px;
  transition: 0.3s;
}
.navbar-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-theme-accent1);
  list-style-position: inside;
}
.navbar-dropdown {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle1 {
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle1:hover {
  color: var(--dl-color-theme-accent1);
  background-color: var(--dl-color-theme-neutral-dark);
}
.navbar-text {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 24 px;
  font-style: normal;
  font-weight: 600;
}
.navbar-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle2 {
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle2:hover {
  color: var(--dl-color-theme-accent1);
  background-color: var(--dl-color-theme-neutral-dark);
}
.navbar-text01 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 24 px;
  font-style: normal;
  font-weight: 600;
}
.navbar-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle3 {
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle3:hover {
  color: var(--dl-color-theme-accent1);
  background-color: var(--dl-color-theme-neutral-dark);
}
.navbar-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 24 px;
  font-style: normal;
  font-weight: 600;
}
.navbar-pricing-nav {
  font-size: 24px;
  text-decoration: none;
  padding: 0 10px;
}

.navbar-digmkt-nav{
  
  font-size: 24px;
  padding: 0 10px;
  text-decoration: none;
}

.navbar-webdev-nav {
  font-size: 24px;
  padding: 0 10px;
  text-decoration: none;
}

.navbar-cloud-nav{
  padding: 0 10px;
  font-size: 24px;
  text-decoration: none;
}

.navbar-contact-div{
  width: 30%;
  text-wrap: nowrap;
}

.navbar-navlink04 {
  display: contents;
}
.navbar-about-nav {
  font-size: 24px;
  padding: 0 10px;
  text-decoration: none;
}
.navbar-contact-nav {
  font-size: 24px;
  text-decoration: none;
  float: right;
}
.navbar-logo-nav {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 0px;
  text-decoration: none;
}

.navbar-collapse-icon {
  display: none;
}
.navbar-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-close-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-logo-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.navbar-navlink05 {
  display: contents;
}
.navbar-dps-logo {
  width: 200px;
  object-fit: cover;
  text-decoration: none;
}
.navbar-links {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-services-drop {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle4 {
  fill: #080A45;
  color: #080A45;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-text03 {
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  margin-right: 4px;
  vertical-align: middle;
}
.navbar-text04 {
  text-align: center;
  margin-right: 4px;
  vertical-align: middle;
}
.navbar-dropdown-arrow1 {
  transition: 0.3s;
}
.navbar-icon5 {
  width: 18px;
  height: 18px;
  transition: 0.3s;
}
.navbar-dropdown-list1 {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  list-style-position: inside;
}
.navbar-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle5 {
  fill: #080A45;
  color: #080A45;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #080A45;
}
.navbar-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle6 {
  fill: #080A45;
  color: #080A45;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #080A45;
}
.navbar-text07 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle7 {
  fill: #080A45;
  color: #080A45;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle7:hover {
  fill: #fff;
  color: #fff;
  background-color: #080A45;
}
.navbar-text08 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-text09 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.navbar-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.navbar-dropdown-toggle8 {
  fill: #080A45;
  color: #080A45;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.navbar-dropdown-toggle8:hover {
  fill: #fff;
  color: #fff;
  background-color: #080A45;
}
.navbar-text11 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.navbar-text12 {
  width: 100%;
  cursor: pointer;
  display: flex;
}
.navbar-pricing-nav1 {
  text-decoration: none;
}
.navbar-about-nav1 {
  text-decoration: none;
}
.navbar-contact-nav1 {
  text-decoration: none;
}




@media(max-width: 1600px) {
  .navbar-navbar-interactive {
    padding: 0px;
  }
  .navbar-navbar {
    margin-right: var(--dl-space-space-twounits);
  }
}


.navbar-pricing-nav {
  font-size: 20px;
  text-decoration: none;
  padding: 0 10px;
}

.navbar-digmkt-nav{
  
  font-size: 20px;
  padding: 0 10px;
  text-decoration: none;
}

.navbar-webdev-nav {
  font-size: 20px;
  padding: 0 10px;
  text-decoration: none;
}

.navbar-cloud-nav{
  padding: 0 10px;
  font-size: 20px;
  text-decoration: none;
}

.navbar-contact-div{
  width: 20%;
  text-wrap: nowrap;
}

.navbar-about-nav {
  font-size: 20px;
  padding: 0 10px;
  text-decoration: none;
}
.navbar-contact-nav {
  font-size: 20px;
  text-decoration: none;
  float: right;
}
.navbar-navlink04 {
  display: contents;
}


@media(max-width: 1600px) {
  .navbar-navbar-interactive {
    padding: 0px;
  }
  .navbar-navbar {
    margin-right: var(--dl-space-space-twounits);
  }
}

@media(max-width:1400px){
.navbar-pricing-nav, .navbar-digmkt-nav, .navbar-webdev-nav, .navbar-cloud-nav, .navbar-about-nav, .navbar-contact-nav {
  font-size: 16px;
}
}


@media(max-width: 991px) {
  .navbar-navbar-interactive {
    justify-content: space-between;
  }
  .navbar-image {
    display: flex;
  }
  .navbar-desktop-menu {
    display: none;
  }
  .navbar-dropdown-list {
    display: none;
  }
  .navbar-collapse-icon {
    display: flex;
  }
  .navbar-mobile-menu {
    background-color: var(--dl-color-theme-accent1);
  }
  .navbar-top {
    align-self: center;
    margin-bottom: 0px;
    justify-content: flex-end;
  }
  .navbar-logo-container {
    justify-content: center;
  }
  .navbar-dps-logo {
    margin-top: -16px;
    width: auto;
    height: var(--dl-size-size-small);
    align-self: center;
  }
  .navbar-dropdown-toggle4 {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .navbar-text03 {
    font-size: 16px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
  .navbar-dropdown-list1 {
    top: 0;
    left: 100%;
    display: none;
  }
  .navbar-dropdown-toggle5 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-text06 {
    font-size: 14px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
  .navbar-dropdown-toggle6 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-text07 {
    font-size: 14px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
  .navbar-dropdown-toggle7 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-text08 {
    font-size: 14px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
  .navbar-dropdown-toggle8 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-text11 {
    font-size: 14px;
    font-style: normal;
    font-family: Bricolage Grotesque;
    font-weight: 600;
  }
}
@media(max-width: 791px) {
  .navbar-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-collapse-icon {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
