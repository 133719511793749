.home-testimonial-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-testimonial-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 1200px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-testimonial-text {
  font-size: 72px;
}
.home-testimonial-container02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonial-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-testimonial-container03 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-testimonial-text02 {
  font-size: 24px;
}
.home-testimonial-text03 {
  font-size: 20px;
}
.home-testimonial-text04 {
  font-size: 18px;
  text-align: left;
}
.home-testimonial-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonial-image1 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-testimonial-container05 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-testimonial-text05 {
  font-size: 24px;
}
.home-testimonial-text06 {
  font-size: 20px;
}
.home-testimonial-text07 {
  font-size: 18px;
  text-align: left;
}
.home-testimonial-container06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonial-image2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-testimonial-container07 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-testimonial-text08 {
  font-size: 24px;
}
.home-testimonial-text09 {
  font-size: 20px;
}
.home-testimonial-text10 {
  font-size: 18px;
  text-align: left;
}
.home-testimonial-container08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonial-image3 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-testimonial-container09 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-testimonial-text11 {
  font-size: 24px;
}
.home-testimonial-text12 {
  font-size: 20px;
}
.home-testimonial-text13 {
  font-size: 18px;
  text-align: left;
}
.home-testimonial-text14 {
  display: inline-block;
  font-size: 24px;
  text-align: center;
}

@media(max-width: 991px) {
  .home-testimonial-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 791px) {
  .home-testimonial-container {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-testimonial-text{
    font-size: 56px;
  }
  .home-testimonial-card {
    width: 100%;
  }
  .home-testimonial-card1 {
    width: 100%;
  }
  .home-testimonial-card2 {
    width: 100%;
  }
  .home-testimonial-card3 {
    width: 100%;
  }
}
