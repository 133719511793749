.web-development-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.web-development-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 56px;
  text-align: center;
}
.web-development-text01 {
  display: inline-block;
  font-size: 20px;
  text-align: center;
}
.web-development-content1 {
  display: inline-block;
}
.web-development-text04 {
  display: inline-block;
}
.web-development-content3 {
  display: inline-block;
}
.web-development-text07 {
  display: inline-block;
}
.web-development-text08 {
  display: inline-block;
  align-self: flex-start;
}
.web-development-text09 {
  display: inline-block;
  font-size: 20px;
}
.web-development-text10 {
  display: inline-block;
}
.web-development-text11 {
  display: inline-block;
}
.web-development-content4 {
  display: inline-block;
}
.web-development-text14 {
  display: inline-block;
}
.web-development-text15 {
  display: inline-block;
}
.web-development-text16 {
  display: inline-block;
}
.web-development-text17 {
  display: inline-block;
}
.web-development-text18 {
  display: inline-block;
}
.web-development-text19 {
  display: inline-block;
}
@media(max-width: 1600px) {
  .web-development-text03 {
    line-height: 2;
  }
  .web-development-text14 {
    font-size: 18px;
  }
  .web-development-text15 {
    font-size: 18px;
  }
  .web-development-text16 {
    font-size: 18px;
  }
  .web-development-text17 {
    font-size: 18px;
  }
}
@media(max-width: 991px) {
  .web-development-text {
    text-align: center;
  }
  .web-development-text01 {
    text-align: center;
  }
}
