.team14-max-width {
  gap: var(--dl-space-space-twounits);
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team14-button {
  width: 100%;
  max-width: 300px;
}
.team14-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-slider {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
  align-self: center;
}
.team14-slider-slide {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text04 {
  font-style: normal;
  font-weight: 600;
}
.team14-text05 {
  text-align: center;
}
.team14-text06 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card01 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title01 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text07 {
  font-style: normal;
  font-weight: 600;
}
.team14-text08 {
  text-align: center;
}
.team14-text09 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card02 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title02 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text10 {
  font-style: normal;
  font-weight: 600;
}
.team14-text11 {
  text-align: center;
}
.team14-text12 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card03 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title03 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text13 {
  font-style: normal;
  font-weight: 600;
}
.team14-text14 {
  text-align: center;
}
.team14-text15 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card04 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title04 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text16 {
  font-style: normal;
  font-weight: 600;
}
.team14-text17 {
  text-align: center;
}
.team14-text19 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card05 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title05 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text21 {
  font-style: normal;
  font-weight: 600;
}
.team14-text23 {
  text-align: center;
}
.team14-text24 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card06 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title06 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text25 {
  font-style: normal;
  font-weight: 600;
}
.team14-text27 {
  text-align: center;
}
.team14-text28 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-card07 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-content08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-title07 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.team14-text30 {
  font-style: normal;
  font-weight: 600;
}
.team14-text31 {
  text-align: center;
}
.team14-text32 {
  overflow: auto;
  max-height: var(--dl-size-size-large);
  text-align: left;
}
.team14-social-icons07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-pagination {
  display: block;
}
.team14-slider1 {
  width: 100%;
  height: auto;
  display: none;
  position: relative;
}
.team14-slider-slide02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title08 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text33 {
  font-style: normal;
  font-weight: 600;
}
.team14-text35 {
  text-align: center;
}
.team14-text36 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title09 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text37 {
  font-style: normal;
  font-weight: 600;
}
.team14-text38 {
  text-align: center;
}
.team14-text39 {
  overflow: hidden;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title10 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text40 {
  font-style: normal;
  font-weight: 600;
}
.team14-text41 {
  text-align: center;
}
.team14-text42 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card11 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title11 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text43 {
  font-style: normal;
  font-weight: 600;
}
.team14-text44 {
  text-align: center;
}
.team14-text45 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title12 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text46 {
  font-style: normal;
  font-weight: 600;
}
.team14-text47 {
  text-align: center;
}
.team14-text48 {
  overflow: hidden;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card13 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title13 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text49 {
  font-style: normal;
  font-weight: 600;
}
.team14-text50 {
  text-align: center;
}
.team14-text51 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title14 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text52 {
  font-style: normal;
  font-weight: 600;
}
.team14-text53 {
  text-align: center;
}
.team14-text54 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-slide09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team14-card15 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-content16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-title15 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team14-text55 {
  font-style: normal;
  font-weight: 600;
}
.team14-text56 {
  text-align: center;
}
.team14-text57 {
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team14-social-icons15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team14-slider-pagination1 {
  display: block;
}
.team14-root-class-name {
  display: none;
}
@media(max-width: 791px) {
  .team14-max-width {
    padding: var(--dl-space-space-threeunits);
  }
  .team14-slider {
    display: none;
  }
  .team14-slider1 {
    display: block;
  }
}
@media(max-width: 479px) {
  .team14-max-width {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .team14-text {
    text-align: center;
  }
  .team14-button {
    width: 100%;
  }
  .team14-text03 {
    text-align: center;
  }
}
