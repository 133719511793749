.testimonial18-max-width {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.testimonial18-container {
  align-items: start;
}
.testimonial18-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  top: 10%;
  display: flex;
  position: sticky;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial18-container1 {
  gap: var(--dl-space-space-twounits);
  width: auto;
  display: flex;
  align-items: flex-start;
}
.testimonial18-content {
  grid-area: span 1/span 1/span 1/span 1;
}
.testimonial18-column {
  top: 10%;
  position: sticky;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-neutral-light);
}
.testimonial18-stars {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial18-text {
  height: auto;
}
.testimonial18-avatar {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.testimonial18-avatar-image {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial18-avatar-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial18-text01 {
  height: auto;
  font-style: normal;
  font-weight: 600;
}
.testimonial18-text02 {
  height: auto;
}
.testimonial18-column1 {
  top: 14%;
  position: sticky;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-neutral-light);
}
.testimonial18-stars1 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial18-text03 {
  height: auto;
}
.testimonial18-avatar1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.testimonial18-avatar-image1 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial18-avatar-content1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial18-text04 {
  height: auto;
  font-style: normal;
  font-weight: 600;
}
.testimonial18-text05 {
  height: auto;
}
.testimonial18-column2 {
  top: 18%;
  position: sticky;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-theme-neutral-light);
}
.testimonial18-stars2 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial18-text06 {
  height: auto;
}
.testimonial18-avatar2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.testimonial18-avatar-image2 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial18-avatar-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial18-text07 {
  height: auto;
  font-style: normal;
  font-weight: 600;
}
.testimonial18-text08 {
  height: auto;
}
.testimonial18-column3 {
  top: 22%;
  position: sticky;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  background-color: var(--dl-color-theme-neutral-light);
}
.testimonial18-stars3 {
  fill: var(--dl-color-theme-primary1);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.testimonial18-review4 {
  height: auto;
}
.testimonial18-avatar3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
}
.testimonial18-author4 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.testimonial18-avatar-content3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.testimonial18-author41 {
  height: auto;
  font-style: normal;
  font-weight: 600;
}
.testimonial18-text09 {
  height: auto;
}
.testimonial18-root-class-name {
  display: none;
}
@media(max-width: 1600px) {
  .testimonial18-root-class-name {
    display: none;
  }
}
@media(max-width: 991px) {
  .testimonial18-max-width {
    flex-direction: column;
  }
}
@media(max-width: 791px) {
  .testimonial18-section-title {
    position: static;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .testimonial18-container1 {
    flex-wrap: wrap;
  }
  .testimonial18-button {
    flex: 1;
  }
  .testimonial18-button1 {
    flex: 1;
  }
  .testimonial18-column {
    width: 100%;
  }
  .testimonial18-column1 {
    width: 100%;
  }
  .testimonial18-column2 {
    width: 100%;
  }
  .testimonial18-column3 {
    width: 100%;
  }
}
