.home-why-us-container {
  flex: 0 0 auto;
  width: 100%;
  display: inline;
  padding: var(--dl-space-space-twounits);
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-why-us-container01 {
  flex: 0 0 auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}
.home-why-us-text01 {
  display: inline-block;
}
.home-why-us-text02 {
  display: inline-block;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-why-us-container02 {
  width: 45%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-why-us-container03 {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.home-why-us-icon {
  width: 40px;
  height: auto;
}
.home-why-us-container04 {
  flex: initial;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-why-us-container05 {
  width: 45%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-why-us-container06 {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.home-why-us-icon2 {
  width: 40px;
  height: auto;
}
.home-why-us-container07 {
  flex: initial;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-why-us-container08 {
  width: 45%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-why-us-container09 {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.home-why-us-icon4 {
  width: 40px;
  height: auto;
}
.home-why-us-container10 {
  flex: initial;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-why-us-text09 {
  display: inline-block;
}
.home-why-us-text10 {
  width: 100%;
  display: inline-block;
  padding: var(--dl-space-space-twounits);
  font-size: 72px;
  text-align: center;
}
.home-why-us-text11 {
  display: inline-block;
}
.home-why-us-text12 {
  display: inline-block;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-why-us-text13 {
  display: inline-block;
}
.home-why-us-text14 {
  display: inline-block;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}
.home-why-us-text15 {
  display: inline-block;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-unit);
}

@media(max-width: 991px){

  .home-why-us-container03, .home-why-us-container06, .home-why-us-container09, .feature-card-container1 {
    height: 60px;
    width: 60px;
  }
}

@media(max-width: 791px) {
  .home-why-us-container {
    flex-direction: column;
  }
  .home-why-us-text10{
    text-align: left;
  }
  .home-why-us-container01 {
    flex-direction: column;
  }
  .home-why-us-container02, .home-why-us-container05, .home-why-us-container08{
    width: 100%;
  }
  .home-why-us-text02, .home-why-us-text12,.home-why-us-text14,.home-why-us-text15{
    font-size: 24px;
  }
  .home-why-us-text01{
    font-size: 18px;
  }
}