.not-found1-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found1-text {
  display: inline-block;
  margin-top: var(--dl-space-space-sixunits);
}
.not-found1-text1 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  display: inline-block;
  font-size: 252px;
  margin-top: -20px;
  font-weight: 900;
  margin-bottom: -20px;
  letter-spacing: -20px;
}
.not-found1-text2 {
  display: inline-block;
  text-align: center;
  font-weight: 400;
}
